<template>
  <div class="gamedownload">
    <img
      src="https://cdn.bubbleplan.cn/static/home/back_cc.png"
      alt=""
      class="gamedownload-bach"
      @click.stop="back()"
    />
    <div class="gamedownload-box flex flex-direction items-center">
      <img
        src="https://cdn.bubbleplan.cn/static/home/gamedownload_title.png"
        alt=""
        class="gamedownload-box-img"
      />
      <img
        src="https://cdn.bubbleplan.cn/static/home/gamedownload_border.png"
        alt=""
        class="gamedownload-box-img2"
      />
      <div></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      history.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.gamedownload {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/home/gamedownload_bgc.png")
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding-top: 438px;
  &-bach {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 76px;
    left: 90px;
    z-index: 9;
  }
  &-bach:hover {
    cursor: pointer;
  }
  &-box {
    &-img {
      width: 422px;
      height: 141px;
    }
    &-img2 {
      width: 764px;
      height: 48px;
    }
  }
}
</style>